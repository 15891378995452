var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ToggleBlock',{ref:"toggleBlock",staticClass:"nav--toggle",attrs:{"id":"nav","active":true,"offset":_vm.offset},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('h3',{attrs:{"id":"navigation","data-test":"navigation-mobile"}},[_vm._v("\n      "+_vm._s(_vm.activeLinkText)+"\n    ")]),_vm._v(" "),_c('SvgIcon',{staticClass:"nav__svg",attrs:{"name":"chevron-down"}})]},proxy:true},{key:"hidden",fn:function(){return [_c('nav',{staticClass:"nav--horizontal",attrs:{"data-test":"nav-panel","aria-label":"Subject level"}},[_c('a',{staticClass:"nav__link",class:{ 'nav__link--current': _vm.setCurrent(`${_vm.urlstem}/`) },attrs:{"href":`${_vm.urlstem}/#nav`,"data-test":"nav-link-overview"}},[_vm._v("\n        "+_vm._s(_vm.$t('component.headernavlinks.overview'))+"\n      ")]),_vm._v(" "),(_vm.offeringType === 'course' || _vm.pathway)?_c('a',{staticClass:"nav__link",class:{
          'nav__link--current': _vm.setCurrent(`${_vm.urlstem}/entry-requirements/`),
        },attrs:{"href":`${_vm.urlstem}/entry-requirements/#nav`,"data-test":"nav-link-entry-requirements"}},[_vm._v("\n        "+_vm._s(_vm.$t('component.headernavlinks.entry-requirements'))+"\n      ")]):_vm._e(),_vm._v(" "),_c('a',{staticClass:"nav__link",class:{ 'nav__link--current': _vm.setCurrent(`${_vm.urlstem}/structure/`) },attrs:{"href":`${_vm.urlstem}/structure/#nav`,"data-test":"nav-link-course-structure"}},[_vm._v("\n        "+_vm._s(_vm.structureLabel)+"\n      ")]),_vm._v(" "),(_vm.offeringType !== 'honours-specialisation')?_c('a',{staticClass:"nav__link",class:{
          'nav__link--current': _vm.setCurrent(`${_vm.urlstem}/career-outcomes/`),
        },attrs:{"href":`${_vm.urlstem}/career-outcomes/#nav`,"data-test":"nav-link-career-outcomes"}},[_vm._v("\n        "+_vm._s(_vm.$t('component.headernavlinks.career-outcomes'))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.offeringType === 'course')?_c('a',{staticClass:"nav__link",class:{
          'nav__link--current': _vm.setCurrent(`${_vm.urlstem}/student-experience/`),
        },attrs:{"href":`${_vm.urlstem}/student-experience/#nav`,"data-test":"nav-link-student-experience"}},[_vm._v("\n        "+_vm._s(_vm.$t('component.headernavlinks.student-experience'))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.offeringType === 'course')?_c('a',{staticClass:"nav__link",class:{ 'nav__link--current': _vm.setCurrent(`${_vm.urlstem}/fees/`) },attrs:{"href":`${_vm.urlstem}/fees/#nav`,"data-test":"nav-link-fees-scholarships"}},[_vm._v("\n        "+_vm._s(_vm.$t('component.headernavlinks.fees-scholarships'))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.offeringType === 'course')?_c('a',{staticClass:"nav__link",class:{
          'nav__link--current': _vm.setCurrent(`${_vm.urlstem}/how-to-apply/`),
        },attrs:{"href":`${_vm.urlstem}/how-to-apply/#nav`,"data-test":"nav-link-how-to-apply"}},[_vm._v("\n        "+_vm._s(_vm.$t('component.headernavlinks.how-to-apply'))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.customTab)?_c('a',{staticClass:"nav__link",class:{
          'nav__link--current': _vm.setCurrent(
            `${_vm.urlstem}/${_vm.sluggifyCustomTab}/`,
          ),
        },attrs:{"href":`${_vm.urlstem}/${_vm.sluggifyCustomTab}/#nav`,"data-test":"nav-link-custom-tab"}},[_vm._v("\n        "+_vm._s(_vm.customTab.title)+"\n      ")]):_vm._e()])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }