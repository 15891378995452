<template>
  <SlimlineCta :links="links" title="How can we help?" />
</template>

<script>
import { SlimlineCta } from '@unimelb/pattern-lib-vue';

export default {
  components: {
    SlimlineCta,
  },
  props: {
    isMspaceCourse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    links() {
      const links = [];
      if (this.isMspaceCourse) {
        links.push(
          {
            title: 'Find a course',
            url: 'https://study.unimelb.edu.au/find/?collection=find-a-course&profile=_default&query=%21showall&num_ranks=12&start_rank=1&f.Tabs%7CtypeCourse=Courses&f.Study+level%7CcourseStudyLevel=micro-credentials+and+short+courses',
          },
          {
            title: 'Support centre',
            url: 'https://study.unimelb.edu.au/study-with-us/professional-development/contact-us/?in_c=hcta',
          },
        );
      } else {
        links.push(
          {
            title: 'Find a course',
            url: 'https://study.unimelb.edu.au/find?in_c=hcta',
          },
          {
            title: 'How to apply',
            url: 'https://study.unimelb.edu.au/how-to-apply?in_c=hcta',
          },
          {
            title: 'Support centre',
            url: 'https://study.unimelb.edu.au/support?in_c=hcta',
          },
          {
            title: 'Contact us',
            url: 'https://study.unimelb.edu.au/support/contact?in_c=hcta',
          },
        );
      }
      return links;
    },
  },
};
</script>
